.red
{
    color:red;
}

.green
{
    color:green;
}

.pink
{
    color:pink;
}

.blue
{
    color:blue;
}

.gray
{
    color:gray;
}
