/*
Jauge sondage
*/

.conteneur-jauge-sondage
{
    background-color:grey
}

.jauge-sondage
{
    background-color:green;
}

/*
jauge complétion de profil
 */

@for $i from 0 through 100
{
    $max : 150;
    $blue : 20;
    $k : $i * $max / 100;

    .conteneur-jauge-completion-#{$i}
    {
        @if($i == 0)
        {
            background-color: rgb($max, 0, $blue);
        }
        @else
        {
            background-color: grey;
        }
    }

    .jauge-completion-#{$i}
    {
        background-color:rgb($max - $k, $k, $blue)
    }
}

/*
Texte dans la jauge
 */

.progress span {
    position: absolute;
    display: block;
    width: 100%;
    color: white;
}
