// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import "~bootswatch/dist/cyborg/variables";
@import '~bootstrap/scss/bootstrap';
@import "~bootswatch/dist/cyborg/bootswatch";

//video
@import "~video.js/src/css/vjs.scss";

@import "layout";

@import "font_awesome";

@import "ressources";

@import "couleurs";

@import "jauge";

// photos

@import '~photoviewer/dist/photoviewer.css';
//@import url('https://www.jqueryscript.net/demo/Responsive-Photo-Viewer/css/photoviewer.css');
//@import url('https://www.jqueryscript.net/demo/Responsive-Photo-Viewer/css/snack.css');
//@import url('https://www.jqueryscript.net/demo/Responsive-Photo-Viewer/css/snack-helper.css');
//@import url('https://www.jqueryscript.net/demo/Responsive-Photo-Viewer/css/docs.css');
