body
{
    background: url('/images/background.jpg');
    background-size: cover ;
    background-repeat: no-repeat ;
    background-attachment: fixed;
    font-size: 1.20rem;
}

.card
{
    opacity : 0.95;
}

.pagination
{
    display: -ms-flexbox;
    flex-wrap: wrap;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.card-margin
{
    //margin-top:20px;
    //margin-bottom:20px;
}

.chat-visibilite
{
    float:right;
}

.visibilite
{
    color:white;
}

.en-ligne
{
    float:right;
    color:red;
}

.card-img-overlay .en-ligne
{
    padding:5px;
    border-radius: 10%;
    background-color: rgba(255, 255, 255, 0.5);
    color:red;
}

.card-img-overlay .visibilite
{
    padding:5px;
    border-radius: 10%;
    background-color: rgba(255, 255, 255, 0.5);
    color:white;
}

.table td
{
    vertical-align : middle;
}
