.resource-titre
{
}

.resource
{
    max-height:500px;
}

img.ressource
{
    max-width:100%;
    max-height:500px;
}

.card-media
{
    margin-top: 30px;
}

.miniature-card
{
}

.album-body
{
}

.miniature-ressource
{
    max-height:100px;
    margin-right:1.5em;
}

.miniature-media-container
{
    position:relative;
    overflow:hidden;
}

// Hack pour contrôler la hauteur
.miniature-media-container:after {
    content: "";
    display: block;
    // Ratio
    padding-bottom: 80%;
}

.miniature-media
{
    position:absolute;
}

.miniature-circle
{
    width:75px;
    height:75px;
}

.miniature-recit
{
}

.album-miniature
{
    max-height:100px;
    overflow:hidden;
}

.album-miniature-circle
{
    width:100px;
    height:100px;
}

.profile-name
{
    height:50px;
    display:inline;
}

.card-media .profile-name
{
    display:block;
}

.avatar-miniature
{
    width: 50px;
    max-height: 50px;
    overflow:hidden;
}

.ressource-body img
{

    width:500px;
    max-height:450px;
    max-width:100%;
}

#my-player
{
    width:500px;
    max-height:500px;
    padding-top:50%;
}
